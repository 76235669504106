// Voeg custom info control toe aan kaart object
var info = L.control();

info.onAdd = function (map) {
  this._div = L.DomUtil.create('div', 'info'); // create a div with a class "info"
  this.update();
  return this._div;
};

// method that we will use to update the control based on feature properties passed
info.update = function (props) {
  var content = 'Gegevens onbekend';
  if (props) {
    content = props !== false 
    ? 
    `<h4>Gemeente: ${props.naam}</h4>
    <table>
      <tr><td></td><th>2020</th><th>2030</th></tr>
      <tr><td>Woningen nabij windturbine (x 1000)</td>
        <td>${Number((props.n_affected_2020)).toLocaleString('nl')}</td>
        <td>${Number((props.n_affected_2030)).toLocaleString('nl')}</td>
      </tr>
      <tr><td>Woningen nabij windturbine<span class="footnote-hint">*</span></td>
        <td>${Number((props.n_affected_2020 / props.woningen * 100).toFixed(1)).toLocaleString('nl')}%</td>
        <td>${Number((props.n_affected_2030 / props.woningen * 100).toFixed(1)).toLocaleString('nl')}%</td>
        </tr>
      <tr><td>Totale ontwaarding (x 1000 €)</td>
        <td>${Number(props.delta_2020).toLocaleString('nl')}</td>
        <td>${Number(props.delta_2030).toLocaleString('nl')}</td>
      </tr>
      <tr><td>Ontwaarding<span class="footnote-hint">*</span></td>
        <td>${Number(props.percentage_2020.toFixed(1)).toLocaleString('nl')}%</td>
        <td>${Number(props.percentage_2030.toFixed(1)).toLocaleString('nl')}%</td>
      </tr>
    </table>
    <p class="footnote"><span class="footnote-hint">*)</span> <em>Betreft een percentage ten opzichte van alle woningen in de gemeente</em></p>
    ` 
    :
     `<h4>Gemeente: ${props.naam}</h4><p>Gemeentegegevesn onbekend</p>`;
  } else {
    content = '<h4>Hover over een gemeente</h4>';
  }
  
  this._div.innerHTML = content;
};

export {info};