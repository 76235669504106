import {BRTAurl, bounds} from './pdok.js';
import {info} from './info.js';

const OSMurl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

const OSM = L.tileLayer(OSMurl, {
  attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
  minZoom: 1,
  maxZoom: 24,
  tileSize: 256,
  zoomOffset: 0
});

const BRT = L.tileLayer(BRTAurl, {
  WMTS: true,
  attribution: '© <a href="https://www.tno.nl/" title="TNO" target="_blank">TNO kaart Nederland</a> | <a href="https://www.cbs.nl" title="Kaartgegevens: www.cbs.nl" target="_blank">CBS</a></span>',
  minZoom: 1,
  maxZoom: 24,
  tileSize: 256,
  zoomOffset: 0
});

// Kaart aanmaken
const map = L.map('map', {
  // crs: RD,
  maxBounds: bounds,
  bounds: bounds,
  zoomControl: false,
  minZoom: 8,
  maxZoom: 13,
  center: [52.15640963614587, 5.38794872514622],
  layers: [OSM] // Ingeschakelde kaartlagen
});

// On switching overlays, reload the legend
// map.on('overlayadd', (e) => {
//   showOrHideLegend(e.name);
// });

// Default controls on load
L.control.scale( {position: 'bottomright'} ).addTo(map);
info.addTo(map);

export { map };