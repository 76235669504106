import { colorsPwg, colorsOntw } from './colors.js';
import { info } from './info.js';

// use https://colorbrewer2.org/#type=sequential&scheme=OrRd&n=9
function getColor(colors, d) {
  // make sure d is a float
  if (Object.prototype.toString.call(d) === "[object String]") { d = parseFloat(d.replace(',','.')); }

  // get color based on brackets
  var colorset;
  if (false === d) { // undefined
    colorset = colors[colors.length - 1];
  } else if (d === colors[colors.length - 2].value) { // 0
    colorset = colors[colors.length - 2];
  } else { // in bracket
    for (var i = 0; i <= colors.length - 1; i++) {
      if (d < colors[i].value) {
        colorset = colors[i];
      }
    }
  }
  // if (!colorset) { console.log('color undefined for value ' + d)}

  var color = colorset ? colorset.color : 'color undefined';
  return color;
}

function styleBlanco() {
  return {
    weight: 2,
    opacity: 1,
    color: '#666',
    dashArray: '3',
    fillOpacity: 0
  }
}

function stylePwg(value) {
  return {
      fillColor: getColor(colorsPwg, value),
      weight: 2,
      opacity: 1,
      color: 'white',
      dashArray: '3',
      fillOpacity: 0.7
  };
}

function styleOntw(value) {
  return {
      fillColor: getColor(colorsOntw, value),
      weight: 2,
      opacity: 1,
      color: 'white',
      dashArray: '3',
      fillOpacity: 0.7
  };
}

function highlightFeature(e) {
  var layer = e.target;

  layer.setStyle({
      weight: 5,
      color: '#666',
      dashArray: '',
      fillOpacity: 0.7
  });

  if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
  }

  info.update(layer.feature.properties);
}

function resetHighlight(e) {
  e.target.setStyle({
    weight: 2,
    color: 'white',
    dashArray: '3',
  });
  info.update();
}

function highlightBlancoFeature(e) {
  var layer = e.target;

  layer.setStyle({
      weight: 5,
  });

  if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
  }

  info.update(layer.feature.properties);
}

function resetBlanco(e) {
  e.target.setStyle({
    weight: 2,
    opacity: 1,
    color: '#666',
    dashArray: '3',
    fillOpacity: 0
  });
  info.update();
}

function zoomToFeature(e) {
  // map.fitBounds(e.target.getBounds());
  // map.setView(e.target.getBounds().getCenter(), 7);
}

export { styleBlanco, stylePwg, styleOntw, highlightFeature, resetHighlight, highlightBlancoFeature, resetBlanco, zoomToFeature };