// color brackets, used to apply color to value _below_ selected index
// e.g.: brackets 0.01 and 0.03 should apply color for 0.03 to 0.02
const colorsPwg = [
  { 'value': 100, 'color': '#d6277a'},
  { 'value': 80, 'color': '#ffcb00'},
  { 'value': 60, 'color': '#649ec9'},
  { 'value': 40, 'color': '#387d99'},
  { 'value': 20,  'color': '#93a800'},
  { 'value': 0,  'color': '#93a800'},
];

const colorsOntw = [
  { 'value': 12, 'color': '#bd1221'},
  { 'value': 4, 'color': '#ed8000'},
  { 'value': 2.5, 'color': '#ffcb00'},
  { 'value': 1, 'color': '#387d99'},
  { 'value': 0.25, 'color': '#649ec9'},
  { 'value': 0, 'color': '#649ec9'},
];

export { colorsPwg, colorsOntw };
