function drawBuffers(data, obj) {
  const circleLayer = L.glify.layer({
    types: {
      shapes: data
    },
    paneName: 'turbines', // class name for z-index ordering
    options: {
      interactive: false
    },
    glifyOptions: {
      color: {r: 0, g: 0, b: 0, a: 0}, 
      size: 10,
      // opacity: 0.5,
      sensitivity: 3,
      border: true,
      borderOpacity: .6,
      // preserveDrawingBuffer: true, // adjusts the border opacity separate from opacity. CAUTION: May cause performance issue with large data sets.
      click (e, feature, xy) {
        // if (Array.isArray(feature)){
        //     L.popup()
        //     // its a [lng,lat] 
        //     .setLatLng(feature.reverse())
        //     .setContent(`You clicked on a point`)
        //     .openOn(map);
        // } else {
        //     L.popup()
        //     .setLatLng(e.latlng)
        //     .setContent('You clicked on a ' + feature)
        //     .openOn(map);
        // }  
      },
      hover(e, feature) {
        // console.log('hover', {feature});
      }        
    },
    onAdd(){
        // console.log('onAdd callback');
    },
    onLayersInit(){
        // console.log('onLayersInit callback');
    },
    onRemove(){
        // console.log('onRemove callback');
    }
  });

  return circleLayer;
}

export { drawBuffers };