import { styleBlanco, stylePwg, styleOntw, highlightFeature, highlightBlancoFeature, resetHighlight, resetBlanco, zoomToFeature } from './feature.js';

function onEachFeature(feature, layer, type) {
  /**
   * "woningen": 5694, 
   * "n_affected_2020": 0, 
   * "n_affected_2030": 0, 
   * "woz_sum_without_wt": 838006.0, 
   * "woz_sum_with_wt_2020": 838006.0, 
   * "woz_sum_with_wt_2030": 838006.0, 
   * "delta_2020": 0.0, 
   * "delta_2030": 0.0,
   * "percentage_2020": 0.0, 
   * "percentage_2030": 0.0 
   */
  let property, style;

  switch (type) {
    case 'blanco':
      style = styleBlanco();
    break;
    case 'pgw2020': 
      property = feature.properties.n_affected_2020 / feature.properties.woningen * 100;
      style = stylePwg(property);
      break;
    case 'pgw2030': 
      property = feature.properties.n_affected_2030 / feature.properties.woningen * 100; 
      style = stylePwg(property);
    break;
    case 'ontw2020': 
      property = feature.properties.percentage_2020; 
      style = styleOntw(property);
    break;
    case 'ontw2030': 
      property = feature.properties.percentage_2030; 
      style = styleOntw(property);
    break;
  }

  if ('blanco' === type) {
    layer.on({
      mouseover: highlightBlancoFeature,
      mouseout: resetBlanco,
      click: zoomToFeature
    });
  } else {
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
      click: zoomToFeature
    });
  }

  layer.setStyle(style); 
}

// Geojson laag (om gemeenten op te streamen)
const blanco2020 = L.geoJson(null, { onEachFeature: (feature, layer) => {onEachFeature(feature, layer, 'blanco')} });
const blanco2030 = L.geoJson(null, { onEachFeature: (feature, layer) => {onEachFeature(feature, layer, 'blanco')} });
const pgw2020 = L.geoJson(null, { onEachFeature: (feature, layer) => {onEachFeature(feature, layer, 'pgw2020')} });
const pgw2030 = L.geoJson(null, { onEachFeature: (feature, layer) => {onEachFeature(feature, layer, 'pgw2030')} });
const ontw2020 = L.geoJson(null, { onEachFeature: (feature, layer) => {onEachFeature(feature, layer, 'ontw2020')} });
const ontw2030 = L.geoJson(null, { onEachFeature: (feature, layer) => {onEachFeature(feature, layer, 'ontw2030')} });

export { blanco2020, blanco2030, pgw2020, pgw2030, ontw2020, ontw2030 };