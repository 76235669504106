import { group2020ontw, group2020pwg, group2030ontw, group2030pwg, groupBlanco2020, groupBlanco2030, groupWT2020, groupWT2030 } from "./groups";
import { showOrHideLegend } from "./legend";
import { map } from "./map";

// Instantiate custom Layer Control
L.Control.CustomLayerControl = L.Control.extend({
  options: {
      position: 'bottomright'
  },
  
  onAdd: function(map) {
    var container = L.DomUtil.create('div', 'leaflet-control-layers leaflet-control-layers-expanded');
    // Use a child input.
    container.innerHTML = `
      <div class="leaflet-control-layers__form-wrapper">
        <form>
          <label class="custom-layer-toggle">
            <span class="toggle__label">2020</span>
            <input class="leaflet-control-layers-overlays" id="yearToggle" type="checkbox" checked/>
            <span class="toggle"></span>
            <span class="toggle__label">2030</span>
          </label>
          <div class="custom-layer-control__layers">
            <p>Kaartlaag</p>
            <label class="radio__label"><input type="radio" name="layerSwitch" value="gemeenten" />Gemeentegrenzen</label>
            <label class="radio__label"><input type="radio" name="layerSwitch" value="woningen" checked />Woningen (%)*</label>
            <label class="radio__label"><input type="radio" name="layerSwitch" value="ontwaarding" />Ontwaarding (%)*</label>
          </div>
          <p>*) <span class="footnote">nabij windturbines</span></p>
        </form>
      </div>
    `;

    return container;
  }

});

// Store instantiated Custom Layer Control
var customLayerControl = new L.Control.CustomLayerControl();

// Store custom controller state
var layerToggle = true; // true: 2030, false: 2020
var layerName = 'woningen'; // gemeenten, woningen, ontwaarding
var currentLayer = group2030pwg;
var currentWTLayer = groupWT2030;

// Custom Layer switch handlers
function handleCustomLayerToggle() {
  layerToggle = this.checked;
  switchLayer();
}

function handleCustomLayerRadios() {
  layerName = this.value;
  switchLayer();
}

function switchLayer() {
  var newCurrentLayer;
  var legend;

  switch (layerName) {
    case 'gemeenten':
      newCurrentLayer = layerToggle ? groupBlanco2030 : groupBlanco2020;
      legend = layerToggle ? {year: 2030, layerName: 'blanco'} : {year: 2020, layerName: 'blanco'};
    break;
    case 'woningen':
      newCurrentLayer = layerToggle ? group2030pwg : group2020pwg;
      legend = layerToggle ? {year: 2030, layerName: 'woningen'} : {year: 2020, layerName: 'woningen'};
    break;
    case 'ontwaarding':
      newCurrentLayer = layerToggle ? group2030ontw : group2020ontw;
      legend = layerToggle ? {year: 2030, layerName: 'ontwaarding'} : {year: 2020, layerName: 'ontwaarding'};
    break;
  }
  
  map.removeLayer(currentLayer);
  map.removeLayer(currentWTLayer);
  map.addLayer(newCurrentLayer);
  map.addLayer(layerToggle ? groupWT2030 : groupWT2020);
  currentLayer = newCurrentLayer;
  currentWTLayer = layerToggle ? groupWT2030 : groupWT2020;
  showOrHideLegend(legend);
}

export {currentLayer, customLayerControl, handleCustomLayerToggle, handleCustomLayerRadios};