import { streamGemeenten } from './modules/gemeenten.js';
import { map } from './modules/map.js';
import { pgw2030 } from './modules/streamlayers.js';
import { drawTurbinesWBuffers } from './modules/turbinesWBuffers.js';
import { zoomHome } from './modules/zoom.js';
import { group2030pwg, groupWT2030 } from './modules/groups.js';
import { customLayerControl, handleCustomLayerRadios, handleCustomLayerToggle } from './modules/customLayerControl.js';
import { legend } from './modules/legend.js';


function init() {
  // Change view, triggers zoomend event (can be used to load correct map layer based on zoom)
  map.setView(new L.LatLng(52.15640963614587, 5.38794872514622), 3);

  Promise.all([
    streamGemeenten(), // Stream the JSON response from TNO dataset with LeafletStream, draw onto GeoJSON layers
    drawTurbinesWBuffers( { 
      group: { name: '2030'},
      turbines: { type: 'turbines', path: '../../data/windturbines_2030.geojson', 'color': {r: 38, g: 227, b: 28, a: 1} },
      buffers: { type: 'buffers', path: '../../data/windturbine_buffers_2030.geojson' } 
    }).then(() => {
      map.addLayer(group2030pwg);
    }),
    drawTurbinesWBuffers( { 
      group: { name: '2020' },
      turbines: { type: 'turbines', path: '../../data/windturbines_2020.geojson', 'color': {r: 156, g: 156, b: 158, a: 1} },
      buffers: { type: 'buffers', path: '../../data/windturbine_buffers_2020.geojson' } 
    })
  ]).then(() => {

    /** Add controls, order matters */
    legend.addTo(map);
    zoomHome.addTo(map);
    
    // Add custom layer control toggle
    map.addControl(customLayerControl);

    // Add layer control input listeners
    document.getElementById("yearToggle").addEventListener("click", handleCustomLayerToggle, false);
    var radios = document.getElementsByName('layerSwitch');
    for (var i = 0; i < radios.length; i++) {
      radios[i].addEventListener("click", handleCustomLayerRadios, false);
    }

    groupWT2030.addTo(map);
    pgw2030.addTo(map);
  });
}

window.addEventListener("load", function(){
  // Only start streaming after Start click in Splash screen
  document.getElementById("start").onclick = function(){
    document.getElementById("splash").classList.add('js--started');
    init();
  };
});