const hyperquest = require('hyperquest'),
      through = require('through'),
      geojsonStream = require('geojson-stream');

module.exports.ajax = ajax;
module.exports.layerPipe = layerPipe;
module.exports.featurePipe = featureGLPipe;

function ajax(url, blanco2020, blanco2030, pgw2020, pgw2030, ontw2020, ontw2030) {
  return hyperquest(url)
    .pipe(geojsonStream.parse())
    .pipe(featureGLPipe(blanco2020, blanco2030, pgw2020, pgw2030, ontw2020, ontw2030));
}

function featureGLPipe(blanco2020, blanco2030, pgw2020, pgw2030, ontw2020, ontw2030) {
  return through(function(d) {
    blanco2020.addData(d);
    blanco2030.addData(d);
    pgw2020.addData(d);
    pgw2030.addData(d);
    ontw2020.addData(d);
    ontw2030.addData(d);
  });
}

function layerPipe(layer) {
  return through(function(d) {
    if (layer.addData !== undefined) layer.addData(d.geometry);
    this.queue(d);
  });
}
