import { map } from './map.js';
import { layerControl } from './turbinesGroupedLayerControl.js';
import { drawTurbines } from './turbines.js';
import { drawBuffers } from './buffers.js';
import {groupBlanco2020, groupBlanco2030, group2020pwg, group2030pwg, group2020ontw, group2030ontw, groupWT2020, groupWT2030} from './groups';

function drawTurbinesWBuffers(obj) {
  // Fetch all datasets
  return Promise.all([
    fetch(obj.turbines.path),
    fetch(obj.buffers.path)
  ]).then(function (responses) {
    // Get a JSON object from each of the responses
    return Promise.all(responses.map(function (response) {
      return response.json();
    }));
  }).then(function (datasets) {
    // Draw layers, nest in group
    datasets.map(dataset => {
      let layer = drawType(dataset, obj);
      if (obj.group.name === '2020') {
        groupWT2020.addLayer(layer);
      } else if (obj.group.name === '2030') {
        groupWT2030.addLayer(layer);
      }
    });
  }).catch(function (error) {
    // if there's an error, log it
    console.log(error);
  });
}

function drawType(data, obj) {
  let layer;

  if (data.name.indexOf('buffers') > 0 ) {
    layer = drawBuffers(data, obj.buffers);
  } else {
    layer = drawTurbines(data, obj.turbines);
  }

  return layer;
}

export { drawTurbinesWBuffers };