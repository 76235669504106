import {colorsPwg, colorsOntw} from "./colors.js";

L.Legend = L.Control.extend({
  'onAdd': function (map) {
      // add reference to mapinstance
      map.legend = this;

      // create container
      var container = L.DomUtil.create('div', 'legend-wrapper');

      container.innerHTML = '';
      container.appendChild(generateLegend());

      return container;
  },
  'onRemove': function (map) {
    // remove reference from mapinstance
    delete map.legend;
  },
  // new method for setting innerHTML
  'setContent': function(content) {
    if (!!content) {
      var container = this.getContainer();
      container.innerHTML = '';
      container.appendChild(content);
    }
  }
});

// Legenda object
var legend = new L.Legend({position: 'bottomright'});

let legendLayer = 'woningen';
function setLegendLayer(layer) {
  legendLayer = layer;
}

let colors = colorsPwg;
function setColors(newColors) {
  colors = newColors;
}

let title = 'Woningen (2030)';
function setTitle(legendDetails) {
  switch (legendDetails.layerName) {
    case 'woningen':
      title = `Woningen (${legendDetails.year})`;
      break;
      case 'ontwaarding':
      title = `Ontwaarding (${legendDetails.year})`;
    break;
  }
}

function generateLegend() {
  if (!colors) {
    var div = L.DomUtil.create('div', 'info legend disabled');
  } else {
    var div = L.DomUtil.create('div', 'info legend');
    div.innerHTML += '<h4>' + title + '</h4><p class="subtitle">nabij windturbines</p>';

    // loop through our density intervals and generate a label with a colored square for each interval
    var bracket1, bracket2;
    for (var i = 0; i < colors.length; i++) {
      if (colors.length - 1 == i) { // last bracket with value (0)
        bracket1 = colors[i];
        bracket2 = false;

        // div.innerHTML +=
        // '<div><i style="background:' + bracket1.color + '"></i> ' +
        // bracket1.value + '%</div>';
      } else {
        bracket1 = colors[i];
        bracket2 = colors[i + 1];

        div.innerHTML +=
          '<div><i style="background:' + bracket1.color + '"></i> ' +
          bracket2.value + ' &dash; ' + bracket1.value + '%</div>';
      }
    }
  }
  return div;
}

function showOrHideLegend(legendDetails) {
  setLegendLayer(legendDetails.layerName);

  if (legendLayer.indexOf('ontwaarding') >= 0 || legendLayer.indexOf('woningen') >= 0) {
    if (legendLayer.indexOf('woningen') >= 0) { 
      setColors(colorsPwg); 
      setTitle(legendDetails);
    }
    if (legendLayer.indexOf('ontwaarding') >= 0) { 
      setColors(colorsOntw); 
      setTitle(legendDetails);
    }
  } else {
    setColors(false);
  }

  legend.setContent(generateLegend()); //map.removeControl(legend);
}

export {setLegendLayer, showOrHideLegend, legend};