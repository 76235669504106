import { groupBlanco2020, groupBlanco2030, group2020pwg, group2030pwg, group2020ontw, group2030ontw } from './groups.js';
import { blanco2020, blanco2030, pgw2020, pgw2030, ontw2020, ontw2030 } from './streamlayers.js';
import { layerControl } from './turbinesGroupedLayerControl.js';

const leafletStream = require('../vendor/leaflet-geojson-stream-custom.js'); // https://github.com/node-geojson/leaflet-geojson-stream

function gemeenten_request() {
  return './data/gemeenten.v3_slim.geojson';
  // return './cache/gemeenten-2020-small.json';

  // WFS request 
  // var baseUrl = 'https://geodata.nationaalgeoregister.nl/wijkenbuurten2019/wfs';
  // var defaultParams = {
  //   service: 'WFS',
  //   version: '2.0.0',
  //   request: 'GetFeature',
  //   typeName: 'gemeenten2019',
  //   srsName: 'EPSG:4326',
  //   outputFormat: 'application/json',
  // }; 

  // return (baseUrl + L.Util.getParamString(defaultParams));
}

function streamGemeenten() {
  // let group = L.layerGroup();

  return leafletStream.ajax(gemeenten_request(), blanco2020, blanco2030, pgw2020, pgw2030, ontw2020, ontw2030)
    .on('end', function() {
      groupBlanco2020.addLayer(blanco2020);
      groupBlanco2030.addLayer(blanco2030);
      group2020pwg.addLayer(pgw2020);
      group2030pwg.addLayer(pgw2030);
      group2020ontw.addLayer(ontw2020);
      group2030ontw.addLayer(ontw2030);

      // layerControl.addOverlay(blanco, 'Gemeenten', 'Ontwaarding');
      // layerControl.addOverlay(pgw2020, 'Getroffen woningen (2020)', 'Ontwaarding');
      // layerControl.addOverlay(pgw2030, 'Getroffen woningen (2030)', 'Ontwaarding');
      // layerControl.addOverlay(ontw2020, 'Ontwaarding (2020)', 'Ontwaarding');
      // layerControl.addOverlay(ontw2030, 'Ontwaarding (2030)', 'Ontwaarding');
    });
}

export { streamGemeenten };