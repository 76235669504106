function drawTurbines(data, obj) {
  const pointsLayer = L.glify.layer({
    types: {
      points: data
    },
    paneName: 'turbines', // class name for z-index ordering
    options: {
      interactive: false
    },
    glifyOptions: {
      'color': { r: obj.color.r / 255, g: obj.color.g / 255, b: obj.color.b / 255, a: obj.color.a}, // 0-255 to 0-1 rgb
      size: 10,
      opacity: 0.8,
      sensitivity: 3,
      click (e, feature, xy) {
        // if (Array.isArray(feature)){
        //     L.popup()
        //     // its a [lng,lat] 
        //     .setLatLng(feature.reverse())
        //     .setContent(`You clicked on a point`)
        //     .openOn(map);
        // } else {
        //     L.popup()
        //     .setLatLng(e.latlng)
        //     .setContent('You clicked on a ' + feature)
        //     .openOn(map);
        // }  
      },
      hover(e, feature) {
        // console.log('hover', {feature});
      }        
    },
    onAdd(layer){
        // console.log('onAdd callback');
    },
    onLayersInit(){
        // console.log('onLayersInit callback');
    },
    onRemove(){
        // console.log('onRemove callback');
    }
  });

  return pointsLayer;
}

// function drawGlify(data) {
//   return L.glify.longitudeFirst().points({
//     map,
//     data: data,
//     size: function(i) {
//       return (Math.random() * 17) + 3;
//     },
//     color: () => {
//       return {
//         r: 1,
//         g: 0,
//         b: 0,
//       };
//     },
//     click: (e, pointOrGeoJsonFeature, xy) => {
//       // do something when a point is clicked
//       // return false to continue traversing
//     },
//     hover: (e, pointOrGeoJsonFeature, xy) => {
//       console.log(pointOrGeoJsonFeature);
//       // do something when a point is hovered
//     }
//   })
// }

export { drawTurbines };